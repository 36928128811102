import React from 'react';
import { Link } from 'gatsby';

import '../../assets/sass/main.scss';

const Footer = () => (
  <footer id="footer" className="wrapper">
    <div className="inner">
      <ul className="menu">
        <li>&copy; Sintra Climbing Tours. All rights reserved.</li>
        {/*<li>
                  Design: <a href="http://html5up.net">HTML5 UP</a>
                </li>*/}
        <li>
          <Link to="/privacy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/terms">Terms &amp; Conditions</Link>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
