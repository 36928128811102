import React from 'react';
import { Link } from 'gatsby';

import '../../assets/sass/main.scss';

const Header = () => (
  <header id="header">
    <Link className="title" to="/">
      Sintra Climbing Tours
    </Link>
    <nav>
      <ul>
        {/*<li>
          <Link to="/">Home</Link>
        </li>*/}
        <li>
          <Link to="/tours">Tours</Link>
        </li>
        <li>
          <Link to="/crash-pad-rentals">Crash Pads</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        {/*<li>
          <Link to="/privacy">Privacy</Link>
        </li>
        <li>
          <Link to="/terms">Terms</Link>
        </li>
        <li>
          <Link to="/generic">Generic</Link>
        </li>
        <li>
          <Link to="/elements">Elements</Link>
        </li>*/}
      </ul>
    </nav>
  </header>
);

export default Header;
