import React from 'react';

import pic from '../images/img_2031_.jpg';

import Layout from '../components/common/layout';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';


const Rentals = () => (
  <Layout>
    <Header />

    <div id="wrapper">
      <section id="main" className="wrapper">
        <div className="inner">
          <h1 className="major">Crash Pad Rentals</h1>
          <span className="image fit">
            <img src={pic} alt="" data-position="center center" />
          </span>
          <p>
            We have Crash Pads for rental.
          </p>
          <ul className="actions">
            <li>
              <a href="/contact" className="button primary">
                Get in touch
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default Rentals;
